<div class="wrapper">
	<agm-map [latitude]="location.latitude" [longitude]="location.longitude" [zoom]="location.zoom">
		<agm-marker *ngFor="let pin of parcelsOnMap" [markerClickable]="true" (markerClick)="openPanel(pin)" [latitude]="pin.latitude" [longitude]="pin.longitude">
		</agm-marker>
		<!--<agm-marker [latitude]="location.latitude+0.0005" [longitude]="location.longitude-0.0001">
		</agm-marker>
		<agm-marker [latitude]="location.latitude+0.0002" [longitude]="location.longitude-0.0003">
		</agm-marker>-->
		<agm-circle [latitude]="location.latitude" [longitude]="location.longitude" [(radius)]="circleRadius" [fillColor]="'green'" [circleDraggable]="true" [editable]="true">
		</agm-circle>
	</agm-map>
	<!--<app-search></app-search>-->
	<mat-card>
	<div class="infopane" *ngIf="!pinselected">
		<p>No pin selected</p>
	</div>
	<div class="infopane" *ngIf="pinselected">
		<ul class="parceldata">
			<p>Economic Entity Number: {{pinselected.economicentitynumber}}</p>
			<p>Parcel Description: {{pinselected.parceldescription}}</p>
			<p>Owner Name: {{pinselected.ownername}}</p>
			<p>Street Address: {{pinselected.streetaddress}}</p>
			<p>Parcel Improvement Gross SF: {{pinselected.parcelimprovementgrosssf}}</p>
			<p>Parcel Land: {{pinselected.parcelland}}</p>
			<p>Parcel GLA: {{pinselected.parcelgla}}</p>
			<p>Parcel Year: {{pinselected.parcelyear}}</p>
			<p>Parcel Mailing Adresss: {{pinselected.parcelmailingaddress}}</p>
			<p>Account Number: {{pinselected.accountnum}}</p>
			
		</ul>
	</div>
	</mat-card>
</div>
