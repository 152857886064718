import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'yearfilter'
})
export class YearFilterPipe implements PipeTransform {
	transform(value: any, input: number, evaluator: string) {
		console.log(evaluator);
		if (input) {
			if (evaluator == ">") {
				return value.filter(function(el: any) {
					return el.yearengaged > input;
				})
			}
			if (evaluator == ">=") {
		
				return value.filter(function(el: any) {
					return el.yearengaged >= input;
				})
			}
			if (evaluator == "<") {
		
				return value.filter(function(el: any) {
					return el.yearengaged < input;
				})
			}
			if (evaluator == "<=") {
		
				return value.filter(function(el: any) {
					return el.yearengaged <= input;
				})
			}
			else {
				return value.filter(function(el: any) {
					return el.yearengaged.toString().indexOf(input) > -1;
				})
			}
		}
		return value;
	}
}