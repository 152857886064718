import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule  } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientsComponent } from './clients/clients.component';
import { SearchComponent } from './search/search.component';
import { IdFilterPipe } from './idfilter.pipe';
import { ValuePerFootPipe } from './valueperfoot.pipe';
import { AddressFilterPipe } from './addressfilter.pipe';
import { CityFilterPipe } from './cityfilter.pipe';
import { YearFilterPipe } from './yearfilter.pipe';
import { StateAbbreviationFilterPipe } from './stateabbreviationfilter.pipe';
import { StateNameFilterPipe } from './statenamefilter.pipe';
import { PropertyNameFilterPipe } from './propertynamefilter.pipe';
import { PropertyCountyFilterPipe } from './propertycountyfilter.pipe';
import { PropertyZipFilterPipe } from './propertyzipfilter.pipe';
import { PropertyPortfolioFilterPipe } from './propertyportfoliofilter.pipe';
import { JurisdictionNameFilterPipe } from './jurisdictionnamefilter.pipe';
import { EditDataComponent } from './edit-data/edit-data.component';
import { MapComponent } from './map/map.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ReactiveFormsModule } from '@angular/forms';
import { AnalysisComponent } from './analysis/analysis.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEchartsModule } from 'ngx-echarts';
import { AngularFireAuthModule } from '@angular/fire/auth';
import * as echarts from 'echarts';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NgxChartsModule } from '@swimlane/ngx-charts';

//    NgxChartsModule


@NgModule({
  declarations: [
    AppComponent,
    ClientsComponent,
    SearchComponent,
    IdFilterPipe,
    AddressFilterPipe,
    CityFilterPipe,
    YearFilterPipe,
    StateNameFilterPipe,
    PropertyNameFilterPipe,
    PropertyCountyFilterPipe,
    PropertyZipFilterPipe,
    PropertyPortfolioFilterPipe,
    JurisdictionNameFilterPipe,
    EditDataComponent,
    StateAbbreviationFilterPipe,
    ValuePerFootPipe,
    MapComponent,
    AnalysisComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatCardModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDsQBTmnRakpIIBFgdFIK7fJtrfuUi0Oq4",
      libraries: ["places", "geometry"]
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    NgxEchartsModule.forRoot({
      echarts,
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
