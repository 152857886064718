import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statenamefilter'
})
export class StateNameFilterPipe implements PipeTransform {
    transform(value: any, input: string) {
	        if (input) {
	            input = input.toLowerCase();
	            console.log(value.statename);
	            return value.filter(function (el: any) {
	            	console.log(el.statename);
	                return el.statename.toLowerCase().indexOf(input) > -1;
	            })
	        }
	        return value;
    }
}