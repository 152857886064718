import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { EChartOption } from 'echarts';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GoogleAuthService } from '../../app/google-auth.service';
import * as firebase from 'firebase';

@Component({
	selector: 'app-analysis',
	templateUrl: './analysis.component.html',
	styleUrls: ['./analysis.component.css']
})

export class AnalysisComponent implements OnInit {
	///bettingodds = 1;

	user$: Observable<firebase.User>;
	alljurisdictions: any;
	menuOpen: false;
	slips = [];
	clients = [];
	events = [];
	states = [];
	jurisdictions = [];
	years = [];
	properties = [];
	parcels = [];
	chosenFilter = "Select Field";
	unfiltereddata: any;
	somevalue: any;
	othervalue: any;
	averagevalueperfoot = 0;
	selectLevel = 'parcel';
	selectJurisdiction: any;
	averagerateofreturn: any;
	totalstuff: any;
	currentstate: any;
	propertystate: any;
	statesdata: any;
	filters = [];
	iteratedstate = '';
	graphitems = 'showall';
	selectedItems = [];
	checkoutForm: any;
	fields = [];
	filterfields: any;
	pinfields = [];
	field: any;
	evaluator: any;
	pinselected: any;
	overallteam1odds = 2; 
	showGraph = true;
	parcelsOnMap: Array<Object>;
	wantToNewFilter = false;
	location: Location;
	selectedMarker: Marker;
	circleRadius: number = 100;
	currentfield: any;
	chartOptions: EChartOption = {
		color: ['#3F51B5'],
		xAxis: {
			type: 'category',
			data: [],

		},
		yAxis: {
			type: 'value',
		},
		series: [
			{
				data: [],
				type: 'bar',
			},
		],
	};
	chartOptionsNew: EChartOption = {
		color: ['#3F51B5'],
		xAxis: {
			type: 'category',
			data: [],
		},
		tooltip: {},
		yAxis: {
			type: 'value',
		},
		series: [
			{
				data: [],
				type: 'bar',
			},
		]
	};
	constructor(private firestore: AngularFirestore, private formBuilder: FormBuilder, private googleAuth: GoogleAuthService, private http: HttpClient) {

		this.user$ = this.googleAuth.user
		this.googleAuth.user.toPromise().then(success => {
			console.log(success.uid);
		});

		this.firestore.collection('events').valueChanges().subscribe(data => {
			console.log(data);
			this.events = data;
		});
		this.firestore.collection('slips').valueChanges().subscribe(data => {
			console.log(data);
			this.slips = data;
		});
		console.log(document.querySelector('#menu'));
		//console.log((document.querySelector('#menu')[0] as HTMLElement).style.height);
		// = document.getElementById('wrapper').style.height;
		this.checkoutForm = this.formBuilder.group({
			teambeton: '',
			betamount: ''
		});
		this.othervalue = [{ value: 'this' }, { value: 'that' }];
		this.alljurisdictions = this.firestore.collection('jurisdictions').valueChanges();
		this.firestore.collection('newclients').valueChanges().subscribe(data => {
			console.log(data);
			this.clients = data;
		});
		//, ref => ref.where('clientzip', '==', '11943'.limit(1))
		this.firestore.collection('newclients').get().toPromise().then(querySnapshot => {
			let clientspot = -1;
			var docs = querySnapshot.docs;
			for (let doc of docs) {
				//this.states = firestore.doc(doc.ref.path).collection('states').valueChanges();
				firestore.doc(doc.ref.path).collection('states').valueChanges().subscribe(data => {
					clientspot += 1;

					this.states = this.states.concat(data);
					for (let state of this.states) {
						state.indexofparent = clientspot;
					}
					console.log(data);
					console.log(this.states);
				});
				console.log(firestore.doc(doc.ref.path).collection('states').valueChanges());
				//firestore.doc(doc.ref.path).collection('states').valueChanges().subscribe(data => {
				//	this.statesdata = data;
				//	for (let state of this.statesdata) {
				//		state.parcels = [];
				//	}
				//	console.log(this.statesdata);
				//	console.log(this.statesdata[0]);
				//x});
				firestore.doc(doc.ref.path).collection('states').get().toPromise().then(querySnapshot => {
					let statespot = -1;
					docs = querySnapshot.docs;
					for (let doc of docs) {
						console.log(doc.get('statename'));
						//this.currentstate = doc.get('statename');
						//this.jurisdictions = firestore.doc(doc.ref.path).collection('jurisdictions').valueChanges();
						firestore.doc(doc.ref.path).collection('jurisdictions').valueChanges().subscribe(data => {
							statespot += 1;
							this.jurisdictions = this.jurisdictions.concat(data);
							for (let jurisdiction of this.jurisdictions) {
								jurisdiction.indexofparent = statespot;
							}
							console.log(data);
							console.log(this.jurisdictions);
						});
						firestore.doc(doc.ref.path).collection('jurisdictions').get().toPromise().then(querySnapshot => {
							let jurisspot = -1;
							docs = querySnapshot.docs;
							for (let doc of docs) {

								/*firestore.doc(doc.ref.path).collection('years').valueChanges().subscribe(data => {
									this.years = this.years.concat(data);
									console.log(data);
									console.log(this.years);
								});*/
								//this.years = firestore.doc(doc.ref.path).collection('years').valueChanges();
								firestore.doc(doc.ref.path).collection('properties').valueChanges().subscribe(data => {
									jurisspot += 1;
									this.properties = this.properties.concat(data);
									for (let prop of this.properties) {
										prop.indexofparent = jurisspot;
									}
									console.log(data);
									console.log(this.properties);
								});
								//this.properties = firestore.doc(doc.ref.path).collection('properties').valueChanges();
								firestore.doc(doc.ref.path).collection('properties').get().toPromise().then(querySnapshot => {
									docs = querySnapshot.docs;
									this.propertystate = doc.get('propertystate');
									let i = -1;
									for (let doc of docs) {
										console.log(i);
										i += 1;
										let averagevalueperfoot = 0;
										let longitude = 0;
										let latitude = 0;

										firestore.doc(doc.ref.path).collection('parcels').valueChanges().subscribe(data => {
											//docs = querySnapshot.docs;
											jurisspot += 1;
											this.parcels = this.parcels.concat(data);
											console.log(data);
											console.log(this.parcels);
											for (let obj of this.parcels) {
												
												obj.indexofparent = i;
												averagevalueperfoot += Math.round(parseInt(obj.assesedvalue) / parseInt(obj.parcelland));
												
											}
											console.log(data.length);
											averagevalueperfoot /= data.length;
											this.properties[i].averagevalueperfoot = averagevalueperfoot;
											firestore.doc(doc.ref.path).collection('parcels').get().toPromise().then(querySnapshot => {
											docs = querySnapshot.docs;
											for (let doc of docs){
													console.log("test" + firestore.doc(doc.ref.path));
													var highestyear = 0;
													console.log("hey");
													firestore.doc(doc.ref.path).collection('appeals').valueChanges().subscribe(data => {
														var appeals = data;
														console.log(appeals);
														for (let appeal of appeals){
															if (appeal['Appeal Year'] > highestyear){
																console.log('higher');
																highestyear = appeal['Appeal Year'];
															}
														}
														var index = 0;
														for (let parcel of this.parcels){
															console.log(parcel);
															console.log(appeals.find(appeal => appeal['Appeal Year'] == highestyear));

															if (parcel['Parcel Identifier'] ==  appeals.find(appeal => appeal['Appeal Year'] == highestyear)['Parcel Identifier']){
														 		console.log('this good');
														 		this.parcels[index] = {
																	...parcel,
																	...(appeals.find(appeal => appeal['Appeal Year'] == highestyear))
																} 
															}
														index = index +1;
														}
														

													});
												}
											});
										});
										//this.parcels = firestore.doc(doc.ref.path).collection('parcels').valueChanges();
										firestore.doc(doc.ref.path).collection('parcels').valueChanges().subscribe(data => {
											this.parcelsOnMap = data;
											console.log(data);
											this.somevalue = this.parcels;
											for (let obj of this.somevalue) {
												if (obj['Street Address']){
												this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + obj['Street Address'].replace(' ', '+') + "," + obj['Parcel State'] + "&key=AIzaSyDsQBTmnRakpIIBFgdFIK7fJtrfuUi0Oq4").subscribe(data => {
													let value = data;
													obj.latitude = value['results'][0].geometry.location.lat;
													obj.longitude = value['results'][0].geometry.location.lng;
												});
												}
											}
											this.unfiltereddata = data;

											this.averagerateofreturn = 0;
											//this.averageLevelsOfArray(this.somevalue, 'parcel');
											//this.averageLevelsOfArray(this.clients, 'client');
											console.log(this.statesdata);
											//this.fields = Object.getOwnPropertyNames(this.somevalue[0]);
											this.fields = [];
											this.filterfields = Object.getOwnPropertyNames(this.parcels[0]);
											for (let parcel of this.parcels) {
												this.fields.push(parcel["Parcel Name"]);
											}
											var index = 0;
											for (let parcel of this.parcels) {
															this.parcels[index].valueperfoot = this.parcels[index]['Current Total Taxable Value'] / this.parcels[index]['Parcel Land SF'];
														}
											console.log(this.fields);
											this.calcAverage()
											this.resetGraph(this.selectAnalytic);
											//possibly just have to propagate all the analytic value into database
										});

									}
								});

							}
						})
					}
				});
				//console.log(firestore.doc(doc.ref.path);
			}
			//console.log(that.states);
			//that.isDataAvailable = true;
		});
		console.log(this.selectAnalytic);
	}
	public selectAnalytic = 'persf';
	ngOnInit(): void {
		this.location = {
			latitude: 38.8823,
			longitude: -77.1711,
			mapType: "satelite",
			zoom: 17,
			marker:
			{
				lat: 38.8823,
				lng: -77.1711
			}
		}
	}
	login() {
		var provider = new firebase.auth.GoogleAuthProvider();

		this.googleAuth.signIn().then(success => {
			window.location.reload();
		});
		console.log(this.googleAuth.user);
	}
			/*firebase.auth().signInWithPopup(provider).then(function(result) {
			// This gives you a Google Access Token. You can use it to access the Google API.
			// var token = result.credential.accessToken;
			// The signed-in user info.
			var user = result.user;
			// ...
		}).catch(function(error) {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			// The email of the user's account used.
			var email = error.email;
			// The firebase.auth.AuthCredential type that was used.
			var credential = error.credential;
			// ...
		});*/

	logout() {
		this.googleAuth.signOut();
	}
	changeDataType(obj): void {
		console.log(obj.target.value);
		let iterant = obj.target.value;
		if (this.selectLevel) {
			if (iterant == 'persf') {

			}
			if (iterant == 'trends') {

			}
			if (iterant == 'taxes') {

			}
		}
		this.chartOptions = { ...this.chartOptions, ...this.chartOptionsNew };
	}
	resetValues() {
		for (let jurisdiction of this.jurisdictions) {
			jurisdiction.averagevalueperfoot = 0;
			jurisdiction.latitude = 0;
			jurisdiction.longitude = 0;
			jurisdiction.timesadded = 0;
		}
		for (let property of this.properties) {
			console.log(property.averagevalueperfoot);
			console.log(property.indexofparent);
			this.jurisdictions[property.indexofparent].averagevalueperfoot += property.averagevalueperfoot;
			this.jurisdictions[property.indexofparent].timesadded += 1;
			let timesadded = 0;
			property.latitude = 0;
			property.longitude = 0;
			for (let parcel of this.parcels) {
				if (property["Property Name"] === parcel["Property Name"]) {
					
					console.log('found it');
					if (parcel.latitude && parcel.longitude){
						timesadded += 1;
						property.latitude += parcel.latitude;
						property.longitude += parcel.longitude;
					}
				}
			}
			if (property.latitude && property.longitude){
				property.latitude /= timesadded;
				property.longitude /= timesadded;
			}
		}
		console.log(this.jurisdictions[0]);
		for (let jurisdiction of this.jurisdictions) {
			jurisdiction.averagevalueperfoot = jurisdiction.averagevalueperfoot / jurisdiction.timesadded;
			console.log(jurisdiction.averagevalueperfoot);
		}
		for (let state of this.states) {
			state.averagevalueperfoot = 0;
			state.timesadded = 0;
		}
		for (let jurisdiction of this.jurisdictions) {
			console.log(jurisdiction.averagevalueperfoot);
			console.log(jurisdiction.indexofparent);
			this.states[jurisdiction.indexofparent].averagevalueperfoot += jurisdiction.averagevalueperfoot;
			this.states[jurisdiction.indexofparent].timesadded += 1;
			let juristimesadded = 0;
			console.log(jurisdiction);
			jurisdiction.latitude = 0;
			jurisdiction.longitude = 0;
			console.log(jurisdiction);
			for (let property of this.properties) {
				console.log(jurisdiction);
				console.log(property);
				if (jurisdiction["Parcel Assessing Jurisdiction Identifier"] == property["Parcel Assessing Jurisdiction Identifier"]) {
					
					console.log('found it');
					if (property.latitude && property.longitude){
						juristimesadded += 1;
						jurisdiction.latitude += property.latitude;
						jurisdiction.longitude += property.longitude;
					}
				}
			}
			jurisdiction.latitude /= juristimesadded;
			jurisdiction.longitude /= juristimesadded;
		}
		console.log(this.jurisdictions[0]);
		for (let state of this.states) {
			state.averagevalueperfoot = state.averagevalueperfoot / state.timesadded;
			console.log(state.averagevalueperfoot);
		}
		console.log(this.states);
		for (let client of this.clients) {
			client.averagevalueperfoot = 0;
			client.timesadded = 0;
		}
		for (let state of this.states) {
			console.log(state.averagevalueperfoot);
			console.log(state.indexofparent);
			this.clients[state.indexofparent].averagevalueperfoot += state.averagevalueperfoot || 0;
			if (!state.averagevalueperfoot) { this.clients[state.indexofparent].timesadded -= 1; }
			this.clients[state.indexofparent].timesadded += 1;
			let statetimesadded = 0;
			console.log(state);
			state.latitude = 0;
			state.longitude = 0;
			console.log(state);
			for (let jurisdiction of this.jurisdictions) {
				console.log(jurisdiction);
				console.log(state);
				if (state["State Name"] == jurisdiction["State Name"]) {
					
					console.log('found it');
					if (jurisdiction.latitude && jurisdiction.latitude){
						statetimesadded += 1;
						state.latitude += jurisdiction.latitude;
						state.longitude += jurisdiction.longitude;
					}
				}
			}
			state.latitude /= statetimesadded;
			state.longitude /= statetimesadded;
		}
		console.log(this.clients[0]);
		for (let client of this.clients) {
			client.averagevalueperfoot = client.averagevalueperfoot / client.timesadded;
			console.log(client.averagevalueperfoot);
			/*let clienttimesadded = 0;
			console.log(client);
			client.latitude = 0;
			client.longitude = 0;
			console.log(client);
			for (let state of this.states){
				console.log(state);
				console.log(client);
				if (client["Client Name"] == state["Client Name"]){
					client += 1;
					console.log('found it');
					client.latitude += state.latitude;
					client.longitude += state.longitude;
				}
			}
			client.latitude /= clienttimesadded;
			client.longitude /= clienttimesadded;*/
		}
		for (let client of this.clients) {
			let clienttimesadded = 0;
			console.log(client);
			client.latitude = 0;
			client.longitude = 0;
			console.log(client);
			for (let state of this.states) {
				console.log(state);
				console.log(client);
				if (client["Client Name"] == state["Client Name"]) {
					clienttimesadded += 1;
					console.log('found it');
					client.latitude += state.latitude;
					client.longitude += state.longitude;
				}
			}

			client.latitude /= clienttimesadded;
			client.longitude /= clienttimesadded;
		}
		console.log(this.clients);
	}
	changeLevel(value, analytic): void {
		console.log(this.graphitems);
		//[329, 331, 330]
		//console.log(obj.target.value);
		//this.filters = [];
		console.log(this.filters);
		//for (let property of this.properties) {
		//	property.latitude /= this.properties.length;
		//	property.longitude /= this.properties.length;
		//}
		this.resetValues()
		let iterant = value;
		if (iterant == 'parcel') {
			console.log('setting fields...');
			this.fields = [];
			for (let parcel of this.parcels) {
				this.fields.push(parcel["Parcel Name"]);
			}
			console.log(this.fields);
			console.log('heya');
			this.somevalue = this.parcels;
			this.calcAverage();
			this.filterfields = Object.getOwnPropertyNames(this.parcels[0]);

			this.resetGraph(analytic);
		}
		if (iterant == 'property') {
			console.log('setting fields...');
			this.fields = [];
			for (let property of this.properties) {
				console.log(property);
				console.log(property["Property Name"]);
				this.fields.push(property["Property Name"]);
			}
			console.log(this.fields);
			this.somevalue = this.properties;
			console.log('heya');
			this.calcAverage();
			this.filterfields = Object.getOwnPropertyNames(this.properties[0]);
			this.resetGraph(analytic);
			//this.resetGraph(analytic);
		}
		if (iterant == 'year') {
			console.log('setting fields...');

			for (let jurisdiction of this.jurisdictions) {
				jurisdiction.averagevalueperfoot = jurisdiction.averagevalueperfoot / jurisdiction.timesadded;
			}
			this.somevalue = this.years;
			console.log('heya');
			this.calcAverage();
			this.filterfields = Object.getOwnPropertyNames(this.years[0]);
			this.resetGraph(analytic);
			//this.resetGraph(analytic);
		}
		if (iterant == 'jurisdiction') {
			console.log('setting fields...');
			this.fields = [];
			for (let jurisdiction of this.jurisdictions) {
				this.fields.push(jurisdiction["Parcel Assessing Jurisdiction Identifier"]);
			}
			console.log(this.fields);
			this.somevalue = this.jurisdictions;
			console.log('heya');
			this.calcAverage();
			this.filterfields = Object.getOwnPropertyNames(this.jurisdictions[0]);
			this.resetGraph(analytic);
			//this.resetGraph(analytic);
		}
		if (iterant == 'state') {
			console.log('setting fields...');
			this.fields = [];
			for (let state of this.states) {
				this.fields.push(state["State Name"]);
			}
			this.somevalue = this.states;
			console.log('heya');
			this.calcAverage();
			this.filterfields = Object.getOwnPropertyNames(this.states[0]);
			this.resetGraph(analytic);
			//this.resetGraph(analytic);
		}
		if (iterant == 'client') {
			console.log('setting fields...');
			this.fields = [];
			for (let client of this.clients) {
				this.fields.push(client["Client Name"]);
			}
			this.somevalue = this.clients;
			console.log('heya');
			this.calcAverage();
			this.filterfields = Object.getOwnPropertyNames(this.clients[0]);
			this.resetGraph(analytic);
			//this.resetGraph(analytic);
		}
		/*if (iterant == 'state') {
			let statenamearray = [];
			for (let state of this.statesdata) {
				statenamearray.push(state.statename);
			}
			statenamearray.push('Average');
			this.chartOptionsNew.xAxis.data = statenamearray;
			console.log(this.chartOptionsNew);
		}*/
		this.chartOptions = { ...this.chartOptions, ...this.chartOptionsNew };
	}

	onSubmitAdd(data) {
		console.log(data);
		this.checkoutForm.reset();
		//data.field = this.currentfield;
		this.firestore.collection('events').get().toPromise().then(querySnapshot => {
			var docs = querySnapshot.docs;
      		for (let doc of docs) {

      			if (doc.data().team1 == data.teambeton || doc.data().team2 == data.teambeton){
      				
      				console.log(doc.data().potsize);
      				console.log(data.betamount);
      				
      				var newpotsize = parseFloat(doc.data().potsize) + parseFloat(data.betamount)*0.9;
      				var newmaxbet = (parseFloat(doc.data().potsize) + parseFloat(data.betamount)*0.9)/2;
      				
      				
      				var bettingsoddsteam1 = doc.data().team2bet / doc.data().team1bet;
      				var bettingsoddsteam2 = doc.data().team1bet / doc.data().team2bet;
      				console.log(bettingsoddsteam1);
      				console.log(bettingsoddsteam2);
      				

      				if (doc.data().team1 == data.teambeton){
      					
      					console.log(data);
      					this.firestore.collection('slips').add({
      						bettingsodds: +(bettingsoddsteam1.toFixed(2)),
      						betamount: data.betamount,
      						maxpayout: ((parseFloat(data.betamount) * bettingsoddsteam1) + parseFloat(data.betamount)),
      						teambeton: data.teambeton
      					});
      					if (false/*(parseFloat(data.betamount) * bettingsoddsteam1 + parseFloat(data.betamount)) + doc.data().team1bet >= newpotsize*/) {
      						alert("bet too large");
      					}
      					else {
      						var newbettingodds = (doc.data().team1bet + (parseFloat(data.betamount) * bettingsoddsteam1 + parseFloat(data.betamount)))/(doc.data().team2bet);
      						this.firestore.doc(doc.ref.path).update({
      							bettingodds: newbettingodds,
      							potsize: newpotsize,
      							//maxbetteam1: (newpotsize-doc.data().team1bet)/(bettingsoddsteam1+1),
      							team1bet: doc.data().team1bet + (parseFloat(data.betamount) * bettingsoddsteam1 + parseFloat(data.betamount)),
      						});
      					}
      				}
      				

      				if (doc.data().team2 == data.teambeton){
      					
      					console.log(data);
      					this.firestore.collection('slips').add({
      						bettingsodds: +(bettingsoddsteam2.toFixed(2)),
      						betamount: data.betamount,
      						maxpayout: ((parseFloat(data.betamount) * bettingsoddsteam2) + parseFloat(data.betamount)),
      						teambeton: data.teambeton
      					});
      					if (false/*(parseFloat(data.betamount) * bettingsoddsteam2 + parseFloat(data.betamount)) + doc.data().team2bet >= newpotsize*/) {
      						alert("bet too large");
      					}
      					else {
      						var newbettingodds = (doc.data().team1bet)/(doc.data().team2bet + (parseFloat(data.betamount) * bettingsoddsteam2 + parseFloat(data.betamount)));
      						this.firestore.doc(doc.ref.path).update({
      							bettingodds: newbettingodds,
      							potsize: newpotsize,
      							//maxbetteam2: (newpotsize-doc.data().team1bet)/(bettingsoddsteam2+1),
      							team2bet: doc.data().team2bet + (parseFloat(data.betamount) * bettingsoddsteam2 + parseFloat(data.betamount)),
      						});
      					}
      				}
      			}
      		}
		});
	}
	addFilter(field) {
		this.currentfield = field;
		this.wantToNewFilter = !this.wantToNewFilter;
	}
	wantsToNewFilter(field): boolean {
		if (this.wantToNewFilter == true && this.currentfield == field) {
			return true;
		}
		else {
			return false;
		}
	}
	clearFilter() {
		//clears
	}
	filter(array, filters): any {
		let newarray = array;
		console.log(newarray);
		for (let filter of filters) {
			console.log(filter);
			let filternewarray = [];
			for (let item of newarray) {
				if (filter.evaluator == ">") {
					if (item[filter.field] > filter.value) {
						filternewarray.push(item);
					}
				}
				if (filter.evaluator == ">=") {
					if (item[filter.field] >= filter.value) {
						filternewarray.push(item);
					}
				}
				if (filter.evaluator == "<") {
					if (item[filter.field] < filter.value) {
						filternewarray.push(item);
					}
				}
				if (filter.evaluator == "<=") {
					if (item[filter.field] <= filter.value) {
						filternewarray.push(item);
					}
				}
				if (filter.evaluator == "=") {
					if (item[filter.field] == filter.value) {
						filternewarray.push(item);
					}
				}
			}
			newarray = filternewarray;
		}
		return newarray;
	}
	removeFilter(filter) {
		let j = 0;
		for (let item of this.filters) {
			if (filter.evaluator == item.evaluator && filter.field == item.field) {
				this.filters = this.filters.splice(0, 1);
			}
			j++;
		}
		this.resetValues();
		this.changeLevel(this.selectLevel, this.filters);
		this.filter(this.somevalue, this.filters);
		this.resetGraph('showall');
		console.log(this.filters);
	}
	resetGraph(analytic) {
		if (this.graphitems == 'showall') {
			//maybe iterate through ^^^^^^
			this.selectedItems = [];
			console.log(this.filters);
			console.log(this.filter(this.somevalue, this.filters));
			let parcelnamearray = this.fields;
			let parcelanalyticarray = [];
			for (let item of this.somevalue) {
				if (analytic == 'persf') {
					if (this.selectLevel == 'parcel') {
						parcelanalyticarray.push(8/*item.valueperfoot*/);
					}
					if (this.selectLevel != 'parcel') {
						parcelanalyticarray.push(8 /*item.averagevalueperfoot*/);
					}
				}
			}
			parcelnamearray.push('Average');
			console.log(parcelnamearray);
			console.log(parcelanalyticarray);
			parcelanalyticarray.push(this.averagevalueperfoot);
			this.chartOptionsNew.xAxis.data = parcelnamearray;
			console.log(this.chartOptionsNew.xAxis.data);
			console.log(this.chartOptionsNew.series[0].data);
			this.chartOptionsNew.series[0].data = parcelanalyticarray;
			console.log(this.chartOptionsNew);
			this.chartOptions = { ...this.chartOptions, ...this.chartOptionsNew };
		}
		if (this.graphitems == 'showselected') {
			let itemsnamearray = [];
			let itemsanalyticarray = [];
			for (let item of this.selectedItems) {
				itemsnamearray.push(item["Parcel Name"]);
				//gonna have to fix because the name value only works for parcels ... maybe just change all names to "name" for standard practice
				itemsanalyticarray.push(item.valueperfoot);
			}
			this.chartOptionsNew.xAxis.data = itemsnamearray;
			this.chartOptionsNew.series[0].data = itemsanalyticarray;
			this.chartOptions = { ...this.chartOptions, ...this.chartOptionsNew };
		}
	}
	addToSelected(item) {
		console.log(item);

		console.log(this.selectedItems);
		let newItem = true;
		for (let object of this.selectedItems) {
			console.log(object);
			console.log(item);
			console.log(object.parcelname == item.parcelname);
			if (object.parcelname == item.parcelname) {
				newItem = false;
			}
		}
		if (!newItem) {
			this.selectedItems.splice(this.selectedItems.indexOf(item));
		}
		if (newItem) {
			this.selectedItems.push(item);
		}
		this.resetGraph(this.selectAnalytic);
	}
	openPanel(pin: Object) {
		console.log('hey');
		this.pinselected = pin;
		this.pinfields = [];
		for (let field of Object.getOwnPropertyNames(this.pinselected)) {
			console.log(this.pinselected[field]);
			this.pinfields.push({
				fieldname: field,
				fieldvalue: this.pinselected[field]
			});
		}
	}
	selectAll() {
		this.selectedItems = this.unfiltereddata;
		this.selectedItems.push({ parcelname: 'Average', valueperfoot: this.averagevalueperfoot });
		this.resetGraph(this.selectAnalytic);
	}
	averageLevelsOfArray(object, level) {
		let objectvalueperfoot = 0;
		let averagerateofreturn = 0;
		if (level == 'parcel') {
			for (let item of object) {
				let i = -1;
				let iteratedelement = '';
				//console.log(this.currentstate);
				/*while (this.currentstate != iteratedelement) {
					i += 1;
					console.log(i);
					console.log(this.statesdata);
					console.log(this.statesdata[i]);
					iteratedelement = this.statesdata[i].statename;
					console.log(this.iteratedstate);
				}*/
				i += 1;
				console.log(i);
				objectvalueperfoot += Math.round(parseInt(item.assesedvalue) / parseInt(item.parcelland));
				averagerateofreturn += (Math.round((item.newvalue - item.oldvalue) / item.oldvalue) * 100);
				//possibly problematic when rounding?
				item.rateofreturn = Math.round((item.newvalue - item.oldvalue) / item.oldvalue) * 100;
				//console.log(this.averagerateofreturn);
				console.log(item.assesedvalue);
				item.valueperfoot = Math.round((parseInt(item.assesedvalue) / parseInt(item.parcelland)));
				//this.statesdata[i].parcels.push(item);
			}
			console.log(this.averagerateofreturn);
			objectvalueperfoot /= this.somevalue.length;
			averagerateofreturn /= this.somevalue.length;
			console.log(this.averagerateofreturn);
			//for (let state of this.statesdata) {
			//	let valueperfoot = 0;
			//	for (let parcel of state.parcels) {
			//		valueperfoot += parcel.valueperfoot;
			//	}
			//	state.valueperfoot = Math.round(valueperfoot / state.parcels.length);
			//}
			this.averagerateofreturn = averagerateofreturn;
			this.averagevalueperfoot = objectvalueperfoot;
		}
		if (level == 'property') {
			for (let prop of object) {

			}
		}
		if (level == 'year') {

		}
		if (level == 'jurisdiction') {

		}
		if (level == 'state') {

		}
		if (level == 'client') {
			for (let item of object) {

			}
		}
	}
	calcAverage() {
		this.averagevalueperfoot = 0;
		console.log(this.selectLevel);
		console.log(this.somevalue);
		for (let thing of this.somevalue) {
			console.log(thing);
			console.log(thing.averagevalueperfoot);
			console.log(thing.valueperfoot);
			console.log(this.somevalue.length);
			if (this.selectLevel != 'parcel') {
				console.log('nonparcelaverage');
				this.averagevalueperfoot += Math.round(parseInt(thing.averagevalueperfoot) / this.somevalue.length);
			}
			if (this.selectLevel == 'parcel') {
				console.log('parcelaverage');
				console.log(thing.valueperfoot);
				console.log(this.somevalue.length);
				console.log(parseInt(thing.valueperfoot) / this.somevalue.length);
				this.averagevalueperfoot += parseInt(thing.valueperfoot) / this.somevalue.length;
			}
		}
		this.averagevalueperfoot = Math.round(this.averagevalueperfoot);
		console.log(this.averagevalueperfoot)
	}
	setColor(parcel) {
		//console.log(parcel);
		//console.log(this.pinselected)
		if (parcel == this.pinselected) {
			return { 'color': '#3F51B5' };
		}
		else {
			return { 'color': 'rgba(0,0,0,.87)' };
		}
	}
}
interface Marker {
	lat: number;
	lng: number;
}

interface Location {
	latitude: number;
	longitude: number;
	mapType: string;
	zoom: number;
	marker: Marker;
}
