import { Component, OnInit } from '@angular/core';
import { Client } from '../client';
import { ClientService } from '../client.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import * as excelToJson from 'node_modules/convert-excel-to-json/lib/convert-excel-to-json.js';

@Component({
  selector: 'app-edit-data',
  templateUrl: './edit-data.component.html',
  styleUrls: ['./edit-data.component.css']
})
export class EditDataComponent implements OnInit {
  clients: any;
  states: any;
  jurisdictions: any;
  years: any;
  properties: any;
  parcels: any;
  checkoutForm: any;
  thing: any;
  resultarr: any;
  value: any;
  isDataAvailable: boolean = false;
  selectedlevel: any;
  constructor(private firestore: AngularFirestore,
    private formBuilder: FormBuilder) {
    alert('this component is not working properly and could mess up the database');
    var temp = firestore.collection('clients', ref => ref.where('clientzip', '==', '11943'))
    this.clients = temp.valueChanges();
    let that = this;
    //console.log(this.subCollections(temp, 'states'))
    //this.states = this.subCollections(temp, 'states');
    //this.subCollections(temp, 'states');
    this.firestore.collection('clients', ref => ref.where('clientzip', '==', '11943').limit(1)).get().toPromise().then(querySnapshot => {
      var docs = querySnapshot.docs;
      for (let doc of docs) {
        console.log(that.states);
        that.states = firestore.doc(doc.ref.path).collection('states').valueChanges();
        console.log(firestore.doc(doc.ref.path).collection('states').valueChanges());
        firestore.doc(doc.ref.path).collection('states').get().toPromise().then(querySnapshot => {
          docs = querySnapshot.docs;
          for (let doc of docs) {
            this.jurisdictions = firestore.doc(doc.ref.path).collection('jurisdictions').valueChanges();
            firestore.doc(doc.ref.path).collection('jurisdictions').get().toPromise().then(querySnapshot => {
              docs = querySnapshot.docs;
              for (let doc of docs) {
                this.years = firestore.doc(doc.ref.path).collection('years').valueChanges();
                firestore.doc(doc.ref.path).collection('years').get().toPromise().then(querySnapshot => {
                  docs = querySnapshot.docs;
                  for (let doc of docs) {
                    this.properties = firestore.doc(doc.ref.path).collection('properties').valueChanges();
                    firestore.doc(doc.ref.path).collection('properties').get().toPromise().then(querySnapshot => {
                      docs = querySnapshot.docs;
                      for (let doc of docs){
                        this.parcels = firestore.doc(doc.ref.path).collection('parcels').valueChanges();
                      }
                    })
                  }
                })
              }
            })
          }
        });
        //console.log(firestore.doc(doc.ref.path);
      }
      //console.log(that.states);
      //that.isDataAvailable = true;
    });
    //that.states = this.subCollections('states', 'clientzip', '11943');  
    /*querySnapshot.forEach(function(document){
    console.log("im here");
    console.log(document);
    })
    });*/
    console.log("hey")
    console.log(this.clients)
    console.log(this.states);
    //console.log(this.search(2));

    //console.log(this.subCollections(temp, 'states'));
/*console.log(temp);
console.log(temp.valueChanges());
temp.get().toPromise().then(querySnapshot => {
if (querySnapshot.empty){
console.log("no data found");
} else if (querySnapshot.size > 1){
console.log("no unique data found");
} else{
console.log("got here");
querySnapshot.forEach(documentSnapshot => {
console.log("hey");
this.clients = this.firestore.doc(documentSnapshot.ref);
});
console.log(this.clients.valueChanges());
}
})
console.log(this.clients);
this.clients = this.clients.valueChanges();
/*this.states = firestore.collection('clients').doc('2GISAaZQ4xUlG5uh3fYI').collection('states').valueChanges()
this.jurisdictions = firestore.collection('clients').doc('2GISAaZQ4xUlG5uh3fYI').collection('states').doc('V7qjFcHZK7aHpxHo5AoK').collection('jurisdictions').valueChanges()
this.years = firestore.collection('clients').doc('2GISAaZQ4xUlG5uh3fYI').collection('states').doc('V7qjFcHZK7aHpxHo5AoK').collection('jurisdictions').doc('MALzLog7BkaqtPuKkyEK').collection('years').valueChanges()
this.properties = firestore.collection('clients').doc('2GISAaZQ4xUlG5uh3fYI').collection('states').doc('V7qjFcHZK7aHpxHo5AoK').collection('jurisdictions').doc('MALzLog7BkaqtPuKkyEK').collection('years').doc('RECKrTPAtLZukKf0bewq').collection('properties').valueChanges()
this.parcels = firestore.collection('clients').doc('2GISAaZQ4xUlG5uh3fYI').collection('states').doc('V7qjFcHZK7aHpxHo5AoK').collection('jurisdictions').doc('MALzLog7BkaqtPuKkyEK').collection('years').doc('RECKrTPAtLZukKf0bewq').collection('properties').doc('cin9Pu0qvTFilBlUuMQU').collection('parcels').valueChanges()
*/this.checkoutForm = this.formBuilder.group({
      clientname: '',
      clientaddress: '',
      clienthomecity: '',
      clientidentifier: '',
      clientzip: '',
      yearengaged: ''
    })
  }
  ngOnInit(): void {
  }
  getClients(): void {
    //this.clientService.getClients().subscribe(clients => this.clients = clients);
  }
  onSubmit(data) {
    console.log(data);
    this.checkoutForm.reset();
    this.firestore.collection('clients').add(data);
  }
  onClick() {
    const result = excelToJson({
      sourceFile: 'C:\Users\James\Downloads',
      columnToKey: {
        '*': '{{columnHeader}}'
      }
    });
    console.log(result);
  }
  /* search(depth) {
     this.firestore.collection('clients').get().toPromise().then(querySnapshot => {    
     let docs = querySnapshot.docs;
     //declare string array results = doc.length
     this.value = docs[0].valueChanges();
     for (let doc of docs) {
       if (depth > 1){
       console.log("depth is 2");
       this.firestore.doc(doc.ref.path).collection('states').get().toPromise().then(querySnapshot => {
         let docs2 = querySnapshot.docs;
         for (let doc2 of docs2){
         console.log(this.firestore.doc(doc2.ref.path).valueChanges());
         var resultarr = this.firestore.doc(doc2.ref.path).valueChanges();
         }
       })
       } else {
       console.log(this.firestore.doc(doc.ref.path).valueChanges());
       var resultarr = [this.firestore.doc(doc.ref.path).valueChanges()];
       }
       //console.log(firestore.doc(doc.ref.path);
     }
     console.log(resultarr);
     return resultarr;
   });
   }
   /*subCollections(collectionname, subcollection){
     collectionname.get().toPromise().then(querySnapshot => { 
     console.log(this.states);
     console.log('hey screenshot query');   
     let docs = querySnapshot.docs;
     for (let doc of docs) {
       console.log(this.firestore.doc(doc.ref.path).collection(subcollection).valueChanges()); 
       console.log(this.isDataAvailable);
       console.log(this.firestore.doc(doc.ref.path).collection(subcollection).valueChanges());
       return this.firestore.doc(doc.ref.path).collection(subcollection).valueChanges();
       //console.log(firestore.doc(doc.ref.path);   
     }
     });
   }*/
}
