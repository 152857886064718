import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jurisdictionnamefilter'
})
export class JurisdictionNameFilterPipe implements PipeTransform {
    transform(value: any, input: string) {
	        if (input) {
	            input = input.toLowerCase();
	            return value.filter(function (el: any) {
	                return el.jurisdictionname.toLowerCase().indexOf(input) > -1;
	            })
	        }
	        return value;
    }
}