<nav role="navigation">
	<div id="menuToggle">
		<!--
		A fake / hidden checkbox is used as click reciever,
		so you can use the :checked selector on it.
		-->
		<input class="checkbox" type="checkbox" />
		<span></span>
		<span></span>
		<span></span>
		
		<!--
		Too bad the menu has to be inside of the button
		but hey, it's pure CSS magic.
		-->
		<ul id="menu">
			<li>
			<div style ="display: flex; flex-direction: row; align-items: center;" *ngIf="user$ | async as user; else loggedOut">
				<img style="height: 50px; width: 50px;" class="user-photo" src="{{ user.photoURL }}">
				<a style="margin-left: 5px">
					<!--style="margin-left: 5px; cursor: pointer;" (click)="logout()"-->
				{{ user.displayName }}</a>
			</div>
			<ng-template #loggedOut>
				<a style="cursor: pointer;" (click)="login()">Login</a>
			</ng-template>
		</li>
				<li *ngFor="let slip of slips"><strong *ngIf="slip.teambeton">{{ slip.teambeton }}: ${{ slip.betamount }} at {{slip.bettingsodds}}:1</strong></li>

				<a *ngIf="user$ | async as user" style="cursor: pointer;" (click)="logout()"><li>Log Out</li></a>
			</ul>
		</div>
</nav>

<ul>
	<h1 style="font-size: 70px;margin-top: 4vh;margin-bottom: 6vh;margin-left: 35vh;">Youbets</h1>
	<li *ngFor="let event of events"><mat-card> <img style="height: 50px; width: 50px;" class="user-photo" src="{{ event.photoURL }}"> <h1>{{event.team1}} vs. {{event.team2}} (odds: {{event.bettingodds}} to 1)</h1> <form [formGroup]="checkoutForm" (ngSubmit)="onSubmitAdd(checkoutForm.value)">
		
		<mat-form-field>
						<mat-label for="teambeton">
						Team bet on:
						</mat-label>
						<select formControlName="teambeton" matNativeControl required>
							<option value="{{ event.team1 }}">{{event.team1}}</option>
							<option value="{{ event.team2 }}">{{event.team2}}</option>
						</select>
						</mat-form-field>

						<mat-form-field>
						<mat-label for="betamount">
						Bet Amount:
						</mat-label>
						<input matInput placeholder=" " id="betamount" formControlName="betamount">
						</mat-form-field>
						
						<button style="margin-top: 15px;" id="submit" class="button" mat-raised-button color="primary" type="submit">Submit</button>
	</form></mat-card></li>
</ul>
