<body color="background" class="mat-app-background">
<!--<mat-toolbar color="primary">
<span>{{title}}
</span>
<span>
	<a mat-button routerLink="/clients">Clients</a>
	<a mat-button routerLink="/search">Search</a>
	<a routerLink="/edit-data">Edit Data</a>
	<a mat-button routerLink="/map">Map</a>
	<a mat-button routerLink="/analysis">Analysis</a>
</span>
</mat-toolbar>
-->

<!--<ul>
  <li class="text" *ngFor="let client of clients | async">
    {{client.name}}
  </li>
</ul>-->
<router-outlet></router-outlet>
</body>