<div class="wrapper">
	<ul class="clients">
		<li *ngFor="let client of clients | async">
			<div><p class="clientname" [class.selected]="selectedClient == client"  (click)="onSelectedClient(client)">{{client["Client Name"]}} </p></div>
			<ul class="clientdata" *ngIf="selectedClient == client">
				<p>Name: {{client["Client Name"]}}</p>
				<p>Address: {{client["Client Address"]}}</p>
				<!--<p>Client Abbreviation: {{client.clientabbreviation}}</p>
				<p>Suite Number: {{client.clientsuitenum}}</p>-->
				<p>City: {{client["Client City"]}}</p>
				<!--<p>State: {{client.clienthomestate}}</p>-->
				<p>Zip: {{client["Client Zip"]}}</p>
				<p>Year Engaged: {{client["Year Engaged"]}}</p>
				<p>States: {{client.states | json}}</p>
				<ul class="states">
					<li *ngFor="let state of states | async">
						<p class="statename" [class.selected]="selectedState == state" (click)="onSelectedState(state)">
							{{state.statename}}
						</p>
						<ul class="statedata" *ngIf="selectedState == state">
							<ul class="jurisdictions">
								<li *ngFor="let jurisdiction of jurisdictions | async">
									<p class="jurisdictionname" [class.selected]="selectedJurisdiction == jurisdiction" (click)="onSelectedJurisdiction(jurisdiction)">
										{{jurisdiction.jurisdictionname}}
									</p>
									<ul class="jurisdictiondata" *ngIf="selectedJurisdiction == jurisdiction">
										<ul class="years">
											<li *ngFor="let year of years | async">
												<p class="yearname" [class.selected]="selectedYear == year" (click)="onSelectedYear(year)">
													{{year.yearnum}}
												</p>
												<ul class="yeardata" *ngIf="selectedYear == year">
													<p>Properties:</p>
													<ul class="properties">
														<li *ngFor="let property of properties | async">
															<p class="propertyname"  [class.selected]="selectedProperty == property" (click)="onSelectedProperty(property)">{{property.propertyname}}</p>
															<ul class="propertydata" *ngIf="selectedProperty == property">
																<p>Portfolio: {{property.portfolio}}</p>
																<p>Property Description: {{property.propertydescription}}</p>
																<p>Property City: {{property.propertycity}}</p>
																<p>Property State: {{property.propertystate}}</p>
																<p>Property Zip: {{property.propertyzip}}</p>
																<p>Property County: {{property.propertycounty}}</p>
																<p>Parcels:</p>
																<ul>
																	<li *ngFor="let parcel of parcels | async">
																		<p class="parcelname"  [class.selected]="selectedParcel == parcel" (click)="onSelectedParcel(parcel)">{{parcel.parcelname}}</p>
																		<ul class="parceldata" *ngIf="selectedParcel == parcel">
																			<p>Economic Entity Number: {{parcel.economicentitynumber}}</p>
																			<p>Parcel Description: {{parcel.parceldescription}}</p>
																			<p>Owner Name: {{parcel.ownername}}</p>
																			<p>Street Address: {{parcel.streetaddress}}</p>
																			<p>Parcel Improvement Gross SF: {{parcel.parcelimprovementgrosssf}}</p>
																			<p>Parcel Land: {{parcel.parcelland}}</p>
																			<p>Parcel GLA: {{parcel.parcelgla}}</p>
																			<p>Parcel Year: {{parcel.parcelyear}}</p>
																			<p>Parcel Mailing Adresss: {{parcel.parcelmailingaddress}}</p>
																			<p>Account Number: {{parcel.accountnum}}</p>
																			<li class="clientedit">
																				<select [(ngModel)]="clientvaluetype">
																					<option value="clientname">Name</option>
																					<option value="clientaddress">Address</option>
																					<option value="clienthomecity">City</option>
																					<option value="clientzip">Zip</option>
																					<option value="yearengaged">Year Engaged</option>
																				</select>
																				<form class="clientform" [formGroup]="clientform" (ngSubmit)="onSubmit(client.clientname, clientform.value)">
																					<mat-label for="clientvalue">New Value:</mat-label>
																					<input class="clientvalue" type="text" formControlName="clientvalue">
																					<button class="button" type="submit">Submit</button>
																				</form>
																			</li>
																		</ul>
																	</li>
																</ul>
																<li class="clientedit">
																	<select [(ngModel)]="clientvaluetype">
																		<option value="portfolio">Portfolio</option>
																		<option value="propertydescription">Property Description</option>
																		<option value="propertycity">City</option>
																		<option value="propertyzip">Zip</option>
																		<option value="propertycounty">County</option>
																	</select>
																	<form class="clientform" [formGroup]="clientform" (ngSubmit)="onSubmit(client.clientname, clientform.value)">
																		<mat-label for="clientvalue">New Value:</mat-label>
																		<input class="clientvalue" type="text" formControlName="clientvalue">
																		<button class="button" type="submit">Submit</button>
																	</form>
																</li>
															</ul>
														</li>
														
													</ul>
													<li class="clientedit">
														<select [(ngModel)]="clientvaluetype">
															<option value="yearnum">Year Number</option>
														</select>
														<form class="clientform" [formGroup]="clientform" (ngSubmit)="onSubmit(year.yearnum, clientform.value)">
															<mat-label for="clientvalue">New Value:</mat-label>
															<input class="clientvalue" type="text" formControlName="clientvalue">
															<button class="button" type="submit">Submit</button>
														</form>
													</li>
												</ul>
											</li>
										</ul>
										<li class="clientedit">
											<select [(ngModel)]="clientvaluetype">
												<option value="jurisdictionname">Jurisdiction Name</option>
											</select>
											<form class="clientform" [formGroup]="clientform" (ngSubmit)="onSubmit(jurisdiction.jurisdictionname, clientform.value)">
												<mat-label for="clientvalue">New Value:</mat-label>
												<input class="clientvalue" type="text" formControlName="clientvalue">
												<button class="button" type="submit">Submit</button>
											</form>
										</li>
									</ul>
								</li>
							</ul>
							<li class="clientedit">
								<select [(ngModel)]="clientvaluetype">
									<option value="statename">Name</option>
								</select>
								<form class="clientform" [formGroup]="clientform" (ngSubmit)="onSubmit(state.statename, clientform.value)">
									<mat-label for="clientvalue">New Value:</mat-label>
									<input class="clientvalue" type="text" formControlName="clientvalue">
									<button class="button" type="submit">Submit</button>
								</form>
							</li>
						</ul>
					</li>
				</ul>
				<li class="clientedit">
					<select [(ngModel)]="clientvaluetype">
						<option value="clientname">Name</option>
						<option value="clientaddress">Address</option>
						<option value="clienthomecity">City</option>
						<option value="clientzip">Zip</option>
						<option value="yearengaged">Year Engaged</option>
					</select>
					<form class="clientform" [formGroup]="clientform" (ngSubmit)="onSubmit(client.clientname, clientform.value)">
						<mat-label for="clientvalue">New Value:</mat-label>
						<input class="clientvalue" type="text" formControlName="clientvalue">
						<button class="button" type="submit">Submit</button>
					</form>
				</li>
				
			</ul>
		</li>

		<li class="clientadd">
			<button mat-raised-button color="primary" (click)="this.wantToAdd=!this.wantToAdd">Add Client</button>
					<form *ngIf="wantToAdd" [formGroup]="checkoutForm" (ngSubmit)="onSubmitAdd(checkoutForm.value)">
						<mat-form-field>
							<mat-label for="clientname">
								Name
							</mat-label>
							<input id="clientname" type="text" formControlName="clientname">
						</mat-form-field>
						<div>
							<mat-label for="clientaddress">
								Address
							</mat-label>
							<input id="clientaddress" type="text" formControlName="clientaddress">
						</div>
						<div>
							<mat-label for="clienthomecity">
								Home City
							</mat-label>
							<input id="clienthomecity" type="text" formControlName="clienthomecity">
						</div>
						<div>
							<mat-label for="clientidentifier">
								Identifier
							</mat-label>
							<input id="clientidentifier" type="text" formControlName="clientidentifier">
						</div>
						<div>
							<mat-label for="clientzip">
								Zip
							</mat-label>
							<input id="clientzip" type="text" formControlName="clientzip">
						</div>
						<div>
							<mat-label for="yearengaged">
								Year Engaged
							</mat-label>
							<input id="yearengaged" type="text" formControlName="yearengaged">
						</div>
						<button class="button" type="submit">Submit</button>
					</form>
				</li>
	</ul>
</div>
<!--
<ul>
								<li class="text" *ngFor="let client of clients | async">
																	{{client.name}}
								</li>
</ul>-->