import { Component, OnInit } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit{
    location: Location;
    selectedMarker: Marker;
    circleRadius: number = 100;
    clients: any;
    states: any;
    jurisdictions: any;
    years: any;
    properties: any;
    parcels: any;
    pinselected: any;
    parcelsOnMap: Array<Object>;

    constructor(private firestore: AngularFirestore) {
        this.firestore.collection('clients', ref => ref.where('clientzip', '==', '11943').limit(1)).get().toPromise().then(querySnapshot => {
            var docs = querySnapshot.docs;
            for (let doc of docs) {
                this.states = firestore.doc(doc.ref.path).collection('states').valueChanges();
                console.log(firestore.doc(doc.ref.path).collection('states').valueChanges());
                firestore.doc(doc.ref.path).collection('states').get().toPromise().then(querySnapshot => {
                    docs = querySnapshot.docs;
                    for (let doc of docs) {
                        this.jurisdictions = firestore.doc(doc.ref.path).collection('jurisdictions').valueChanges();
                        firestore.doc(doc.ref.path).collection('jurisdictions').get().toPromise().then(querySnapshot => {
                            docs = querySnapshot.docs;
                            for (let doc of docs) {
                                this.years = firestore.doc(doc.ref.path).collection('years').valueChanges();
                                firestore.doc(doc.ref.path).collection('years').get().toPromise().then(querySnapshot => {
                                    docs = querySnapshot.docs;
                                    for (let doc of docs) {
                                        this.properties = firestore.doc(doc.ref.path).collection('properties').valueChanges();
                                        firestore.doc(doc.ref.path).collection('properties').get().toPromise().then(querySnapshot => {
                                            docs = querySnapshot.docs;
                                            for (let doc of docs) {
                                                this.parcels = firestore.doc(doc.ref.path).collection('parcels').valueChanges();
                                                firestore.doc(doc.ref.path).collection('parcels').valueChanges().subscribe(data => {
                                                    console.log(data);
                                                    this.parcelsOnMap = data;
                                                    console.log(this.parcelsOnMap);
                                                    //possibly just have to propagate all the analytic value into database
                                                });
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                });
                //console.log(firestore.doc(doc.ref.path);
            }
            //console.log(that.states);
            //that.isDataAvailable = true;
        });
    }
    ngOnInit() {
        this.location = {
            latitude: 38.8823,
            longitude: -77.1711,
            mapType: "satelite",
            zoom: 17,
            marker:
                {
                    lat: 38.8823,
                    lng: -77.1711
                }
        }
    }
    openPanel(pin: Object){
        console.log('hey');
        this.pinselected = pin;
    }

}

interface Marker {
    lat: number;
    lng: number;
}

interface Location {
    latitude: number;
    longitude: number;
    mapType: string;
    zoom: number;
    marker: Marker;
}