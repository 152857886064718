import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';

import {
   debounceTime, distinctUntilChanged, switchMap
 } from 'rxjs/operators';

import { Client } from '../client';
import { ClientService } from '../client.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})



export class SearchComponent implements OnInit {
  //clients$: Observable<Client[]>;
  levels = ['clients', 'states', 'jurisdictions', 'years', 'properties', 'parcels'];
  clients: any;
  states: any;
  jurisdictions: any;
  years: any;
  properties: any;
  parcels: any;
  checkoutForm: any;
  thing: any;
  resultarr: any;
  value: any;
  isDataAvailable: boolean = false;
  selectedClient: any;
  selectedState: any;
  selectedJurisdiction: any;
  selectedYear: any;
  selectedProperty: any;
  selectedParcel: any;
  public queryString: any;
  constructor(private firestore: AngularFirestore) {
    alert('search exclusively works only for these levels: Clients, States, jurisdictions, Properties')
    var temp = firestore.collection('clients', ref => ref.where('clientzip', '==', '11943'))
    this.clients = temp.valueChanges();
    let that = this;
    //console.log(this.subCollections(temp, 'states'))
    //this.states = this.subCollections(temp, 'states');
    //this.subCollections(temp, 'states');
    this.firestore.collection('clients', ref => ref.where('clientzip', '==', '11943').limit(1)).get().toPromise().then(querySnapshot => {
      var docs = querySnapshot.docs;
      for (let doc of docs) {
        console.log(that.states);
        that.states = firestore.doc(doc.ref.path).collection('states').valueChanges();
        console.log(firestore.doc(doc.ref.path).collection('states').valueChanges());
        firestore.doc(doc.ref.path).collection('states').get().toPromise().then(querySnapshot => {
          docs = querySnapshot.docs;
          for (let doc of docs) {
            this.jurisdictions = firestore.doc(doc.ref.path).collection('jurisdictions').valueChanges();
            firestore.doc(doc.ref.path).collection('jurisdictions').get().toPromise().then(querySnapshot => {
              docs = querySnapshot.docs;
              for (let doc of docs) {
                this.years = firestore.doc(doc.ref.path).collection('years').valueChanges();
                firestore.doc(doc.ref.path).collection('years').get().toPromise().then(querySnapshot => {
                  docs = querySnapshot.docs;
                  for (let doc of docs) {
                    this.properties = firestore.doc(doc.ref.path).collection('properties').valueChanges();
                    firestore.doc(doc.ref.path).collection('properties').get().toPromise().then(querySnapshot => {
                      docs = querySnapshot.docs;
                      for (let doc of docs){
                        this.parcels = firestore.doc(doc.ref.path).collection('parcels').valueChanges();
                      }
                    })
                  }
                })
              }
            })
          }
        });
        //console.log(firestore.doc(doc.ref.path);
      }
      //console.log(that.states);
      //that.isDataAvailable = true;
    });
  }
  public byID = true;
  public byStateAbbreviation = true;
  public byStateName: any;
  public byPropertyName = true;
  public byJurisdictionName = true;
  public byPropertyCounty: any;
  public byPropertyZip: any;
  public byPropertyPortfolio: any;
  public selectedlevel: any;
  public evaluator: any;
  public byAddress: any;
  public byCity: any;
  public byYear: any;
  ngOnInit(): void {
  	
  }
  
  
  
  getClients(): void {
  	//this.clientService.getClients().subscribe(clients => this.clients = clients);
  }
    onSelectedClient(client: any): void {
    if(this.selectedClient == client){
      this.selectedClient = null;
      this.selectedState = null;
      this.selectedJurisdiction = null;
      this.selectedYear = null;
      this.selectedProperty = null;
      this.selectedParcel = null;
    }
    else{
      this.selectedClient = client;
    }
  }
  onSelectedState(state: any): void {
    if(this.selectedState == state){
      this.selectedState = null;
      this.selectedJurisdiction = null;
      this.selectedYear = null;
      this.selectedProperty = null;
      this.selectedParcel = null;
    }
    else{
      this.selectedState = state;
    }
  }
  onSelectedJurisdiction(jurisdiction: any): void {
    if(this.selectedJurisdiction == jurisdiction){
      this.selectedJurisdiction = null;
      this.selectedYear = null;
      this.selectedProperty = null;
      this.selectedParcel = null;
    }
    else{
      this.selectedJurisdiction = jurisdiction;
    }
  }
  onSelectedYear(year: any): void {
    if(this.selectedYear == year){
      this.selectedYear = null;
      this.selectedProperty = null;
      this.selectedParcel = null;
    }
    else{
      this.selectedYear = year;
    }
  }
  onSelectedProperty(property: any): void {
    if(this.selectedProperty == property){
      this.selectedProperty = null;
      this.selectedParcel = null;
    }
    else{
      this.selectedProperty = property;
    }
  }
  onSelectedParcel(parcel: any): void {
    if(this.selectedParcel == parcel){
      this.selectedParcel = null;
    }
    else{
      this.selectedParcel = parcel;
    }
  }
}
