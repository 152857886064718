<div class="wrapper">
	<br/>
	<br/>
	<select [(ngModel)]="selectedlevel">
		<option value="Clients">Clients</option>
		<option value="States">States</option>
		<option value="Jurisdictions">Jurisdictions</option>
		<option value="Years">Years</option>
		<option value="Properties">Properties</option>
		<option value="Parcels">Parcels</option>
	</select>
	<!--<p>
			{{byID}}
			{{byAddress}}
			{{byCity}}
			{{byYear}}
	</p>-->
	<br/>
	<br/>
	<select [(ngModel)]="evaluator" *ngIf="byYear">
		<option value=">">></option>
		<option value=">="><=</option>
		<option value="<"><</option>
		<option value="<="><=</option>
		<option value="=">=</option>
	</select><input type="text" [(ngModel)]="queryString" id="search" placeholder="Type to Search">
	<br/>
	<br/>
	<div *ngIf="selectedlevel == 'Clients'">
		<label>Sort by ID<input type="checkbox" [(ngModel)]="byID" (change)="byAddress = false; byCity = false; byYear = false;"></label><br/>
		<label>Sort by Address<input type="checkbox" [(ngModel)]="byAddress" (change)="byID = false; byCity = false; byYear = false;"></label><br/>
		<label>Sort by City<input type="checkbox" [(ngModel)]="byCity" (change)="byAddress = false; byID = false; byYear = false;"></label><br/>
		<label>Sort by Year<input type="checkbox" [(ngModel)]="byYear" (change)="byAddress = false; byCity = false; byID = false;"></label>
		<br/>
		<br/>
		<ul *ngIf="byID"class="clients">
			<li *ngFor="let client of clients | async | idfilter : queryString">
				<div><p class="clientname" [class.selected]="selectedClient == client"  (click)="onSelectedClient(client)">{{client.clientname}} </p></div>
				<ul class="clientdata" *ngIf="selectedClient == client">
					<p>ID: {{client.clientidentifier}}</p>
					<p>Address: {{client.clientaddress}}</p>
					<p>Client Abbreviation: {{client.clientabbreviation}}</p>
					<p>Suite Number: {{client.clientsuitenum}}</p>
					<p>City: {{client.clienthomecity}}</p>
					<p>State: {{client.clienthomestate}}</p>
					<p>Zip: {{client.clientzip}}</p>
					<p>Year Engaged: {{client.yearengaged}}</p>
					<p>States:</p>
					<ul class="states">
						<li *ngFor="let state of client.states">
							<p class="statename" [class.selected]="selectedState == state" (click)="onSelectedState(state)">
								{{state.statename}}
							</p>
							<ul class="statedata" *ngIf="selectedState == state">
								<p>stateinfo</p>
								<ul class="jurisdictions">
									<li *ngFor="let jurisdiction of state.jurisdictions">
										<p class="jurisdictionname" [class.selected]="selectedJurisdiction == jurisdiction" (click)="onSelectedJurisdiction(jurisdiction)">
											{{jurisdiction.jurisdictionname}}
										</p>
										<ul class="jurisdictiondata" *ngIf="selectedJurisdiction == jurisdiction">
											<p>jurisdictiondata</p>
											<ul class="years">
												<li *ngFor="let year of jurisdiction.years">
													<p class="yearname" [class.selected]="selectedYear == year" (click)="onSelectedYear(year)">
														{{year.yearnumber}}
													</p>
													<ul class="yeardata" *ngIf="selectedYear == year">
														<p>yeardata</p>
														<p>Properties:</p>
														<ul class="properties">
															<li *ngFor="let property of year.properties">
																<p class="propertyname"  [class.selected]="selectedProperty == property" (click)="onSelectedProperty(property)">{{property.propertyname}}</p>
																<ul class="propertydata" *ngIf="selectedProperty == property">
																	<p>Portfolio: {{property.portfolio}}</p>
																	<p>Property Description: {{property.propertydescription}}</p>
																	<p>Property City: {{property.propertycity}}</p>
																	<p>Property State: {{property.propertystate}}</p>
																	<p>Property Zip: {{property.propertyzip}}</p>
																	<p>Property County: {{property.propertycounty}}</p>
																	<p>Parcels:</p>
																	<ul>
																		<li *ngFor="let parcel of property.parcels">
																			<p class="parcelname"  [class.selected]="selectedParcel == parcel" (click)="onSelectedParcel(parcel)">{{parcel.parcelname}}</p>
																			<ul class="parceldata" *ngIf="selectedParcel == parcel">
																				<p>Economic Entity Number: {{parcel.economicentitynumber}}</p>
																				<p>Parcel Description: {{parcel.parceldescription}}</p>
																				<p>Owner Name: {{parcel.ownername}}</p>
																				<p>Street Address: {{parcel.streetaddress}}</p>
																				<p>Parcel Improvement Gross SF: {{parcel.parcelimprovementgrosssf}}</p>
																				<p>Parcel Land: {{parcel.parcelland}}</p>
																				<p>Parcel GLA: {{parcel.parcelgla}}</p>
																				<p>Parcel Year: {{parcel.parcelyear}}</p>
																				<p>Parcel Mailing Adresss: {{parcel.parcelmailingaddress}}</p>
																				<p>Account Number: {{parcel.accountnum}}</p>
																			</ul>
																		</li>
																	</ul>
																</ul>
															</li>
														</ul>
													</ul>
												</li>
											</ul>
										</ul>
									</li>
								</ul>
							</ul>
						</li>
					</ul>
				</ul>
			</li>
		</ul>
		<ul *ngIf="byAddress"class="clients">
			<li *ngFor="let client of clients | async | addressfilter : queryString">
				{{client.clientaddress}}
			</li>
		</ul>
		<ul *ngIf="byCity"class="clients">
			<li *ngFor="let client of clients | async | cityfilter : queryString">
				{{client.clienthomecity}}
			</li>
		</ul>
		<ul *ngIf="byYear"class="clients">
			<li *ngFor="let client of clients | async | yearfilter : queryString : evaluator">
				{{client.yearengaged}}
			</li>
		</ul>
	</div>
	<div *ngIf="selectedlevel == 'States'">
		<label>Sort by Abbreviation<input type="checkbox" [(ngModel)]="byStateAbbreviation" (change)="byStateName = false;"></label><br/>
		<label>Sort by Name<input type="checkbox" [(ngModel)]="byStateName" (change)="byStateAbbreviation = false;"></label><br/>
		<ul *ngIf="byStateAbbreviation" class="states">
			<li *ngFor="let state of states | async | stateabbreviationfilter : queryString">
				{{state.stateabbreviation}}
			</li>
		</ul>
		<ul *ngIf="byStateName" class="states">
			<li *ngFor="let state of states | async | statenamefilter : queryString">
				{{state.statename}}
			</li>
		</ul>
	</div>
	<div *ngIf="selectedlevel == 'Jurisdictions'">
		<label>Sort by Name<input type="checkbox" [(ngModel)]="byJurisdictionName"></label>
		<br/>
		<br/>
		<ul class="jurisdictions">
			<li *ngFor="let jurisdiction of jurisdictions | async | jurisdictionnamefilter : queryString">
				{{jurisdiction.jurisdictionname}}
			</li>
		</ul>
	</div>
	<div *ngIf="selectedlevel == 'Years'">
		<label>Sort by Year<input type="checkbox" [(ngModel)]="byYear" (change)="byAddress = false; byCity = false; byID = false;"></label>
		<br/>
		<br/>
		<ul class="years">
			<!--need to implement yearnumber filter 
			<li *ngFor="let year of years | async | yearfilter : queryString">
				{{year.yearnumber}}
			</li>
			-->
		</ul>
	</div>
	<div *ngIf="selectedlevel == 'Properties'">
		<label>Sort by Name<input type="checkbox" [(ngModel)]="byPropertyName" (change)="byPropertyCounty = false; byPropertyZip = false; byPropertyPortfolio = false;"></label><br/>
		<label>Sort by County<input type="checkbox" [(ngModel)]="byPropertyCounty" (change)="byPropertyName = false; byPropertyZip = false; byPropertyPortfolio = false;"></label><br/>
		<label>Sort by Zip<input type="checkbox" [(ngModel)]="byPropertyZip" (change)="byPropertyName = false; byPropertyCounty = false; byPropertyPortfolio = false;"></label><br/>
		<label>Sort by Portfolio<input type="checkbox" [(ngModel)]="byPropertyPortfolio" (change)="byPropertyZip = false; byPropertyCounty = false; byPropertyName = false;"></label>
		<br/>
		<br/>
		<ul *ngIf="byPropertyName" class="properties">
			<li *ngFor="let property of properties | async | propertynamefilter : queryString">
				{{property.propertyname}}
			</li>
		</ul>
		<ul *ngIf="byPropertyCounty" class="properties">
			<li *ngFor="let property of properties | async | propertycountyfilter : queryString">
				{{property.propertycounty}}
			</li>
		</ul>
		<ul *ngIf="byPropertyZip" class="properties">
			<li *ngFor="let property of properties | async | propertyzipfilter : queryString">
				{{property.propertyzip}}
			</li>
		</ul>
		<ul *ngIf="byPropertyPortfolio" class="properties">
			<li *ngFor="let property of properties | async | propertyportfoliofilter: queryString">
				{{property.portfolio}}
			</li>
		</ul>
	</div>
	<div *ngIf="selectedlevel == 'Parcels'">
		<label>Sort by ID<input type="checkbox" [(ngModel)]="byID" (change)="byAddress = false; byCity = false; byYear = false;"></label><br/>
		<label>Sort by Address<input type="checkbox" [(ngModel)]="byAddress" (change)="byID = false; byCity = false; byYear = false;"></label><br/>
		<label>Sort by City<input type="checkbox" [(ngModel)]="byCity" (change)="byAddress = false; byID = false; byYear = false;"></label><br/>
		<label>Sort by Year<input type="checkbox" [(ngModel)]="byYear" (change)="byAddress = false; byCity = false; byID = false;"></label>
		<br/>
		<br/>
		<ul class="parcels">
			<li *ngFor="let parcel of parcels | async">
				{{parcel.parcelname}}
			</li>
		</ul>
	</div>
</div>