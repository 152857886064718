// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBQliTrBty7vg_WZEC3BJBn5XfOuRMEAb8",
    authDomain: "database-5ebdc.firebaseapp.com",
    databaseURL: "https://database-5ebdc.firebaseio.com",
    projectId: "database-5ebdc",
    storageBucket: "database-5ebdc.appspot.com",
    messagingSenderId: "868503904785",
    appId: "1:868503904785:web:d316d514dd0224c91f47b0",
    measurementId: "G-4THTY0Y4GM",
    clientId: "868503904785-osf89iuu77gdjqkmq0h0a66cbc5r7f3q.apps.googleusercontent.com"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
