import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientsComponent } from './clients/clients.component';
import { SearchComponent } from './search/search.component';
import { EditDataComponent } from './edit-data/edit-data.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { MapComponent } from './map/map.component';

const routes: Routes = [
	{ path: 'clients', component: ClientsComponent },
	{ path: 'search', component: SearchComponent },
	{ path: 'edit-data', component: EditDataComponent },
	{ path: 'map', component: MapComponent },
	{ path: 'analysis', component: AnalysisComponent },
	{ path: '', redirectTo: '/analysis', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
