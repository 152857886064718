import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Client } from '../client';
import { ClientService } from '../client.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  clients: any;
  states: any;
  jurisdictions: any;
  years: any;
  properties: any;
  parcels: any;
  clientform: any;
  checkoutForm: any;
  wantToAdd: any;

  constructor(private firestore: AngularFirestore, private formBuilder: FormBuilder) {
    alert('property changing only works down to jurisdiction level');
    this.clients = firestore.collection('clients').valueChanges()
    console.log(this.clients)

    this.jurisdictions = firestore.collection('clients').doc('2GISAaZQ4xUlG5uh3fYI').collection('states').doc('V7qjFcHZK7aHpxHo5AoK').collection('jurisdictions').valueChanges()
    this.years = firestore.collection('clients').doc('2GISAaZQ4xUlG5uh3fYI').collection('states').doc('V7qjFcHZK7aHpxHo5AoK').collection('jurisdictions').doc('MALzLog7BkaqtPuKkyEK').collection('years').valueChanges()
    this.properties = firestore.collection('clients').doc('2GISAaZQ4xUlG5uh3fYI').collection('states').doc('V7qjFcHZK7aHpxHo5AoK').collection('jurisdictions').doc('MALzLog7BkaqtPuKkyEK').collection('years').doc('RECKrTPAtLZukKf0bewq').collection('properties').valueChanges()
    this.parcels = firestore.collection('clients').doc('2GISAaZQ4xUlG5uh3fYI').collection('states').doc('V7qjFcHZK7aHpxHo5AoK').collection('jurisdictions').doc('MALzLog7BkaqtPuKkyEK').collection('years').doc('RECKrTPAtLZukKf0bewq').collection('properties').doc('cin9Pu0qvTFilBlUuMQU').collection('parcels').valueChanges()
    this.clientform = this.formBuilder.group({
      clientvalue: ''
    });

    this.checkoutForm = this.formBuilder.group({
      clientname: '',
      clientaddress: '',
      clienthomecity: '',
      clientidentifier: '',
      clientzip: '',
      yearengaged: ''
    });
  }
  //console.log(firestore.collection('clients').doc('client').get());



ngOnInit(): void {
  //this.getClients();
}

onSubmitAdd(data) {
  console.log(data);
  this.checkoutForm.reset();
  this.firestore.collection('clients').add(data);
  this.wantToAdd = false;
}
selectedClient: any;
selectedState: any;
selectedJurisdiction: any;
selectedYear: any;
selectedProperty: any;
selectedParcel: any;
clientvaluetype: any;

onSelectedClient(client: any): void {
  if(this.selectedClient == client) {
  this.selectedClient = null;
  this.selectedState = null;
  this.selectedJurisdiction = null;
  this.selectedYear = null;
  this.selectedProperty = null;
  this.selectedParcel = null;
}
    else {
  this.selectedClient = client;
  this.states = this.firestore.collection('clients').doc('2GISAaZQ4xUlG5uh3fYI').collection('states').valueChanges();
}
  }
onSelectedState(state: any): void {
  if(this.selectedState == state) {
  this.selectedState = null;
  this.selectedJurisdiction = null;
  this.selectedYear = null;
  this.selectedProperty = null;
  this.selectedParcel = null;
}
    else {
  this.selectedState = state;
}
  }
onSelectedJurisdiction(jurisdiction: any): void {
  if(this.selectedJurisdiction == jurisdiction) {
  this.selectedJurisdiction = null;
  this.selectedYear = null;
  this.selectedProperty = null;
  this.selectedParcel = null;
}
    else {
  this.selectedJurisdiction = jurisdiction;
}
  }
onSelectedYear(year: any): void {
  if(this.selectedYear == year) {
  this.selectedYear = null;
  this.selectedProperty = null;
  this.selectedParcel = null;
}
    else {
  this.selectedYear = year;
}
  }
onSelectedProperty(property: any): void {
  if(this.selectedProperty == property) {
  this.selectedProperty = null;
  this.selectedParcel = null;
}
    else {
  this.selectedProperty = property;
}
  }
onSelectedParcel(parcel: any): void {
  if(this.selectedParcel == parcel) {
  this.selectedParcel = null;
}
    else {
  this.selectedParcel = parcel;
}
  }
onSubmit(parent, data) {
  console.log(parent);
  console.log(data);
  console.log(this.clientvaluetype)
  console.log("true");
  if (this.clientvaluetype == "clientname" || this.clientvaluetype == "clientaddress" || this.clientvaluetype == "clienthomecity" || this.clientvaluetype == "clientzip" || this.clientvaluetype == "yearengaged") {
    this.firestore.collection('clients', ref => ref.where('clientname', '==', parent).limit(1)).get().toPromise().then(querySnapshot => {
      console.log('hiy');
      var docs = querySnapshot.docs;
      for (let doc of docs) {
        console.log('howdy');
        if (this.clientvaluetype == "clientname") {
          console.log('hey');
          this.firestore.doc(doc.ref.path).update({
            clientname: data.clientvalue
          });
        }
        if (this.clientvaluetype == "clientaddress") {
          console.log('hey');
          this.firestore.doc(doc.ref.path).update({
            clientaddress: data.clientvalue
          });
        }
        if (this.clientvaluetype == "clienthomecity") {
          console.log('hey');
          this.firestore.doc(doc.ref.path).update({
            clienthomecity: data.clientvalue
          });
        }
        if (this.clientvaluetype == "clientzip") {
          console.log('hey');
          this.firestore.doc(doc.ref.path).update({
            clientname: data.clientvalue
          });
        }
        if (this.clientvaluetype == "yearengaged") {
          console.log('hey');
          this.firestore.doc(doc.ref.path).update({
            clientname: data.clientvalue
          });
        }
        //console.log(firestore.doc(doc.ref.path);
      }
      //console.log(that.states);
      //that.isDataAvailable = true;
    });
    //this.clientform.reset();
    //this.firestore.collection(parent).add(data);
  }
  if (this.clientvaluetype == "statename") {

    this.firestore.collection('clients').get().toPromise().then(querySnapshot => {
      console.log('hiy');
      console.log(parent);
      var docs = querySnapshot.docs;
      for (let doc of docs) {
        console.log('howdy');
        this.firestore.doc(doc.ref.path).collection('states', ref => ref.where('statename', '==', parent).limit(1)).get().toPromise().then(querySnapshot => {
          if (this.clientvaluetype == "statename") {
            console.log('hey');
            console.log(parent);
            docs = querySnapshot.docs;
            for (let doc of docs) {
              console.log(doc.ref.path);
              this.firestore.doc(doc.ref.path).update({
                statename: data.clientvalue
              });
            }
          }
        });
      }
    });
  }
  if (this.clientvaluetype == "jurisdictionname") {
    this.firestore.collection('clients').get().toPromise().then(querySnapshot => {
      console.log('hiy');
      console.log(parent);
      var docs = querySnapshot.docs;
      for (let doc of docs) {
        console.log('howdy');
        this.firestore.doc(doc.ref.path).collection('states').get().toPromise().then(querySnapshot => {
          console.log('hey');
          console.log(parent);
          docs = querySnapshot.docs;
          for (let doc of docs) {
            console.log(doc.ref.path);
            this.firestore.doc(doc.ref.path).collection('jurisdictions', ref => ref.where('jurisdictionname', '==', parent).limit(1)).get().toPromise().then(querySnapshot => {
              console.log('hey')
              docs = querySnapshot.docs;
              for (let doc of docs) {
                console.log('sdkfj');
                if (this.clientvaluetype == "jurisdictionname") {
                  console.log(':)')
                  this.firestore.doc(doc.ref.path).update({
                    jurisdictionname: data.clientvalue
                  });
                }
              }
            });
          }
        });
      }
    });
  }
}
}
            //console.log(firestore.doc(doc.ref.path);
                  //console.log(that.states);
        //that.isDataAvailable = true;

      //this.clientform.reset();
      //this.firestore.collection(parent).add(data);
