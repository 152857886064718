import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { GoogleAuthService } from '../app/google-auth.service';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Real Estate Database';
  
  //clients: any;
  constructor(firestore: AngularFirestore) {
  	//this.clients = firestore.collection('clients').valueChanges()
  	//console.log(firestore.collection('clients').doc('client').get());
  	
  }
  
}
