import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueperfoot'
})
export class ValuePerFootPipe implements PipeTransform {
    transform(value: any) {
	      	console.log(value);
	        //value.valueperfoot = (value.assesedvalue.parseInt() / value.parcelland.parseInt());
	        return value;
    }
}