<h3>Add New Data</h3>
<select [(ngModel)]="selectedlevel">
		<option value="Clients">Clients</option>
		<option value="States">States</option>
		<option value="Jurisdictions">Jurisdictions</option>
		<option value="Years">Years</option>
		<option value="Properties">Properties</option>
		<option value="Parcels">Parcels</option>
</select>
<form *ngIf="selectedlevel=='Clients'" [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
<div>
	<label for="clientname">
		Name
	</label>
	<input id="clientname" type="text" formControlName="clientname">
</div>
<div>
	<label for="clientaddress">
		Address
	</label>
	<input id="clientaddress" type="text" formControlName="clientaddress">
</div>
<div>
	<label for="clienthomecity">
		Home City
	</label>
	<input id="clienthomecity" type="text" formControlName="clienthomecity">
</div>
<div>
	<label for="clientidentifier">
		Identifier
	</label>
	<input id="clientidentifier" type="text" formControlName="clientidentifier">
</div>
<div>
	<label for="clientzip">
		Zip
	</label>
	<input id="clientzip" type="text" formControlName="clientzip">
</div>
<div>
	<label for="yearengaged">
		Year Engaged
	</label>
	<input id="yearengaged" type="text" formControlName="yearengaged">
</div>
<button class="button" type="submit">Submit</button>
</form>
<form *ngIf="selectedlevel=='States'" [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
<div>
	<label for="clientname">
		Name
	</label>
	<input id="clientname" type="text" formControlName="clientname">
</div>
<div>
	<label for="clientaddress">
		Address
	</label>
	<input id="clientaddress" type="text" formControlName="clientaddress">
</div>
<div>
	<label for="clienthomecity">
		Home City
	</label>
	<input id="clienthomecity" type="text" formControlName="clienthomecity">
</div>
<div>
	<label for="clientidentifier">
		Identifier
	</label>
	<input id="clientidentifier" type="text" formControlName="clientidentifier">
</div>
<div>
	<label for="clientzip">
		Zip
	</label>
	<input id="clientzip" type="text" formControlName="clientzip">
</div>
<div>
	<label for="yearengaged">
		Year Engaged
	</label>
	<input id="yearengaged" type="text" formControlName="yearengaged">
</div>
<button class="button" type="submit">Submit</button>
</form>
<form *ngIf="selectedlevel=='Jurisdictions'" [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
<div>
	<label for="clientname">
		Name
	</label>
	<input id="clientname" type="text" formControlName="clientname">
</div>
<div>
	<label for="clientaddress">
		Address
	</label>
	<input id="clientaddress" type="text" formControlName="clientaddress">
</div>
<div>
	<label for="clienthomecity">
		Home City
	</label>
	<input id="clienthomecity" type="text" formControlName="clienthomecity">
</div>
<div>
	<label for="clientidentifier">
		Identifier
	</label>
	<input id="clientidentifier" type="text" formControlName="clientidentifier">
</div>
<div>
	<label for="clientzip">
		Zip
	</label>
	<input id="clientzip" type="text" formControlName="clientzip">
</div>
<div>
	<label for="yearengaged">
		Year Engaged
	</label>
	<input id="yearengaged" type="text" formControlName="yearengaged">
</div>
<button class="button" type="submit">Submit</button>
</form>
<form *ngIf="selectedlevel=='Years'" [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
<div>
	<label for="clientname">
		Name
	</label>
	<input id="clientname" type="text" formControlName="clientname">
</div>
<div>
	<label for="clientaddress">
		Address
	</label>
	<input id="clientaddress" type="text" formControlName="clientaddress">
</div>
<div>
	<label for="clienthomecity">
		Home City
	</label>
	<input id="clienthomecity" type="text" formControlName="clienthomecity">
</div>
<div>
	<label for="clientidentifier">
		Identifier
	</label>
	<input id="clientidentifier" type="text" formControlName="clientidentifier">
</div>
<div>
	<label for="clientzip">
		Zip
	</label>
	<input id="clientzip" type="text" formControlName="clientzip">
</div>
<div>
	<label for="yearengaged">
		Year Engaged
	</label>
	<input id="yearengaged" type="text" formControlName="yearengaged">
</div>
<button class="button" type="submit">Submit</button>
</form>
<form *ngIf="selectedlevel=='Properties'" [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
<div>
	<label for="clientname">
		Name
	</label>
	<input id="clientname" type="text" formControlName="clientname">
</div>
<div>
	<label for="clientaddress">
		Address
	</label>
	<input id="clientaddress" type="text" formControlName="clientaddress">
</div>
<div>
	<label for="clienthomecity">
		Home City
	</label>
	<input id="clienthomecity" type="text" formControlName="clienthomecity">
</div>
<div>
	<label for="clientidentifier">
		Identifier
	</label>
	<input id="clientidentifier" type="text" formControlName="clientidentifier">
</div>
<div>
	<label for="clientzip">
		Zip
	</label>
	<input id="clientzip" type="text" formControlName="clientzip">
</div>
<div>
	<label for="yearengaged">
		Year Engaged
	</label>
	<input id="yearengaged" type="text" formControlName="yearengaged">
</div>
<button class="button" type="submit">Submit</button>
</form>
<form *ngIf="selectedlevel=='Parcels'" [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
<div>
	<label for="clientname">
		Name
	</label>
	<input id="clientname" type="text" formControlName="clientname">
</div>
<div>
	<label for="clientaddress">
		Address
	</label>
	<input id="clientaddress" type="text" formControlName="clientaddress">
</div>
<div>
	<label for="clienthomecity">
		Home City
	</label>
	<input id="clienthomecity" type="text" formControlName="clienthomecity">
</div>
<div>
	<label for="clientidentifier">
		Identifier
	</label>
	<input id="clientidentifier" type="text" formControlName="clientidentifier">
</div>
<div>
	<label for="clientzip">
		Zip
	</label>
	<input id="clientzip" type="text" formControlName="clientzip">
</div>
<div>
	<label for="yearengaged">
		Year Engaged
	</label>
	<input id="yearengaged" type="text" formControlName="yearengaged">
</div>
<button class="button" type="submit">Submit</button>
</form>
<!--
<section>
	<h3>Convert from Excel</h3>
</section>-->